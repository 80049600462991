.intro {
  min-height: 100px;
  margin: 1.5rem 0;
}

@include media-breakpoint-up(md) {
  .home-carousel {
    position: relative;
    height: calc(100vh - 152px);
    overflow: hidden;
  }

  .intro-container {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
  }

  .intro {
    min-height: auto;
    min-width: 500px;
    padding: 2rem;
    margin: 0;
    background-color: #fff;
    opacity: 0.65;
  }
}
