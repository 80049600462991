.html,
body {
  height: 100%;
}

#app {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

#main-container {
  flex-grow: 1;
}
