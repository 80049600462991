.clickable-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;

  p {
    color: #303030;
  }

  .card {
    height: 100%;
  }

  &:hover {
    .card {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
}
