.social-links {
  font-size: 1.3rem;

  a {
    display: block;
    margin-bottom: .5rem;
    text-decoration: none;
  }

  i {
    margin-right: 1rem;
    font-size: 2rem;
  }

  .bi-youtube {
    color: rgb(179, 55, 55);
  }

  .bi-instagram {
    color: rgb(46, 173, 35);
  }

  .bi-envelope-fill {
    color: rgb(35, 92, 173);
  }
}

.social-links-inline {
  a {
    display: inline-block;
  }
}
