.header-container {
  border-bottom: 1px solid #ddd;
}

.header {
  padding: 0.75rem 0;
}

.header-top {
  display: flex;
  align-items: center;

  &-icon {
    width: 64px;
    height: 64px;
  }

  &-title {
    padding-left: 1rem;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.15em;
    color: #444;
  }
}

.header-nav {
  margin-top: 1rem;

  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 300;
    color: #107de1;
  }

  a {
    text-decoration: none;
  }

  li:hover {
    text-decoration: underline;
    color: #1a62a5;
    cursor: pointer;
  }
}

@include media-breakpoint-up(md) {
  .header {
    padding: 1rem 0;
  }

  .header-nav {
    margin-top: 1.5rem;

    ul {
      display: block;
    }

    li {
      font-size: 1.3rem;
    }

    li:not(:first-child) {
      margin-left: 1.5rem;
    }
  }
}
