.tech-icon-link {
  display: flex;
  align-items: center;
  text-decoration: none;

  i {
    margin-right: 0.75rem;
    font-size: 1.2rem;
  }

  .bi-youtube {
    font-size: 1.4rem;
    color: rgb(179, 55, 55);
  }

  .bi-file-earmark-excel-fill {
    color: rgb(46, 173, 35);
  }
}
